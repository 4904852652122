import * as option from "@/models/configurator/configurator_options";

import { FrameGetters, FrameState } from "./types";

import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { Finish } from "@/models/products/finish";
import { StoreModule } from "@/store/types";

export const getFinish = (state: FrameState): Finish | null => {
  return state.finish;
};

export const getFinial = (state: FrameState): option.Finial | null => {
  return state.finial;
};

export const getFinialFinish = (state: FrameState): Finish | undefined => {
  return state.finialFinish;
};

export const getBottomPole = (state: FrameState): option.BottomPole | null => {
  return state.bottomPole;
};

export const getSpigot = (state: FrameState): option.Spigot | null => {
  return state.spigot ?? null;
};

export const getAllFrameData = (
  state: FrameState,
  getters: any,
  rootState: any,
  rootGetters: any
): CustomUmbrella["frame"] => {
  const frame = {} as CustomUmbrella["frame"];
  frame.finish = rootGetters[StoreModule.Frame + FrameGetters.GetFinish];
  frame.finial = rootGetters[StoreModule.Frame + FrameGetters.GetFinial];
  frame.finialFinish =
    rootGetters[StoreModule.Frame + FrameGetters.GetFinialFinish];
  frame.bottomPole =
    rootGetters[StoreModule.Frame + FrameGetters.GetBottomPole];
  return frame;
};
