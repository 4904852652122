import * as option from "@/models/configurator/configurator_options";

import {
  BaseActions,
  BaseGetters
} from "@/store/modules/configurator/base/types";
import {
  CanopyActions,
  CanopyGetters
} from "@/store/modules/configurator/canopy/types";
import { Component, Vue } from "vue-property-decorator";
import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { FabricSwatch, FixedFabricArray } from "@/models/products/fabric";
import {
  FrameActions,
  FrameGetters
} from "@/store/modules/configurator/frame/types";
import {
  LayoutActions,
  LayoutGetters
} from "@/store/modules/configurator/layout/types";
import {
  ShapeActions,
  ShapeGetters
} from "@/store/modules/configurator/shape/types";

import { BaseModel } from "@/models/products/base";
import { Collection } from "@/models/products/collection";
import { Finish } from "@/models/products/finish";
import { StoreModule } from "@/store/types";
import { UmbrellaModel } from "@/models/products/umbrella";
import { namespace } from "vuex-class";

@Component
export class LayoutStore extends Vue {
  /** LAYOUT */
  /** actions */
  @(namespace(StoreModule.Layout).Action(LayoutActions.AddCollection))
  public addCollection!: (value: Collection | null) => Promise<void>;
  @(namespace(StoreModule.Layout).Action(LayoutActions.AddPreset))
  public addPreset!: (value: option.Preset | null) => Promise<void>;
  /** getters */
  @(namespace(StoreModule.Layout).Getter(LayoutGetters.GetCollection))
  public collection!: Collection;
  @(namespace(StoreModule.Layout).Getter(LayoutGetters.GetPreset))
  public preset!: option.Preset;
}

@Component
export class BaseStore extends Vue {
  /* BASE */
  /** actions */
  @(namespace(StoreModule.Base).Action(BaseActions.AddBase))
  public addBase!: (value: BaseModel | null) => Promise<void>;
  @(namespace(StoreModule.Base).Action(BaseActions.AddBaseFinish))
  public addBaseFinish!: (value: Finish | null) => Promise<void>;
  @(namespace(StoreModule.Base).Action(BaseActions.AddBaseStem))
  public addBaseStem!: (value: option.Stem | null) => Promise<void>;
  @(namespace(StoreModule.Base).Action(BaseActions.AddBaseOptions))
  public addBaseOptions!: (value: any | null) => Promise<void>;
  @(namespace(StoreModule.Base).Action(BaseActions.AddAllBaseData))
  public addAllBaseData!: (value: CustomUmbrella["base"]) => Promise<void>;
  /** getters */
  @(namespace(StoreModule.Base).Getter(BaseGetters.GetBase))
  public baseModel!: BaseModel | null;
  @(namespace(StoreModule.Base).Getter(BaseGetters.GetBaseFinish))
  public baseFinish!: Finish | null;
  @(namespace(StoreModule.Base).Getter(BaseGetters.GetBaseStem))
  public baseStem!: option.Stem | null;
  @(namespace(StoreModule.Base).Getter(BaseGetters.GetBaseOptions))
  public baseOptions!: any;
  @(namespace(StoreModule.Base).Getter(BaseGetters.GetAllBaseData))
  public allBaseData!: CustomUmbrella["base"];
}

@Component
export class CanopyStore extends Vue {
  /* CANOPY */
  /** actions */
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddMainCanopy))
  public addMainCanopy!: (value: option.Panel) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddMainFabric))
  public addMainFabric!: (value: FixedFabricArray) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddVentTopFabric))
  public addVentTopFabric!: (value: FixedFabricArray | []) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddVentMiddle))
  public addVentMiddle!: (value: option.Panel) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddVentMiddleFabric))
  public addVentMiddleFabric!: (value: FixedFabricArray | []) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddValanceFabric))
  public addValanceFabric!: (value: FixedFabricArray | []) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddValance))
  public addValance!: (value: option.Valance) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddBindingFabric))
  public addBindingFabric!: (value: FabricSwatch | null) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddRib))
  public addRib!: (value: option.Rib | null) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddRibFabric))
  public addRibFabric!: (value: FabricSwatch | null) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddMainTrimFabric))
  public addMainTrimFabric!: (value: FabricSwatch | null) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddMainTrimFabricInner))
  public addMainTrimFabricInner!: (value: FabricSwatch | null) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddVentTrimFabric))
  public addVentTrimFabric!: (value: FabricSwatch | null) => Promise<void>;
  @(namespace(StoreModule.Canopy).Action(CanopyActions.AddAllCanopyData))
  public addAllCanopyData!: (value: CustomUmbrella["canopy"]) => Promise<void>;
  /** getters */
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetMainCanopy))
  public mainCanopy!: option.Panel;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetMainFabric))
  public mainFabric!: FixedFabricArray;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetVentTopFabric))
  public ventTopFabric!: FixedFabricArray;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetVentMiddle))
  public ventMiddle!: option.Panel;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetVentMiddleFabric))
  public ventMiddleFabric!: FixedFabricArray;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetValanceFabric))
  public valanceFabric!: FixedFabricArray;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetValance))
  public valance!: option.Valance;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetBindingFabric))
  public bindingFabric!: FabricSwatch | null;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetRib))
  public rib!: option.Rib;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetRibFabric))
  public ribFabric!: null | FabricSwatch;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetMainTrimFabric))
  public mainTrimFabric!: null | FabricSwatch;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetMainTrimFabricInner))
  public mainTrimFabricInner!: null | FabricSwatch;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetVentTrimFabric))
  public ventTrimFabric!: null | FabricSwatch;
  @(namespace(StoreModule.Canopy).Getter(CanopyGetters.GetAllCanopyData))
  public allCanopyData!: null | CustomUmbrella["canopy"];
}

@Component
export class FrameStore extends Vue {
  /* FRAME */
  /** actions */
  @(namespace(StoreModule.Frame).Action(FrameActions.AddFinish))
  public addFinish!: (value: Finish | null) => Promise<void>;
  @(namespace(StoreModule.Frame).Action(FrameActions.AddFinial))
  public addFinial!: (value: option.Finial | null) => Promise<void>;
  @(namespace(StoreModule.Frame).Action(FrameActions.AddFinialFinish))
  public addFinialFinish!: (value: Finish | null) => Promise<void>;
  @(namespace(StoreModule.Frame).Action(FrameActions.AddBottomPole))
  public addBottomPole!: (value: option.BottomPole) => Promise<void>;
  @(namespace(StoreModule.Frame).Action(FrameActions.AddSpigot))
  public addSpigot!: (value: option.Spigot) => Promise<void>;
  @(namespace(StoreModule.Frame).Action(FrameActions.AddAllFrameData))
  public addAllFrameData!: (value: CustomUmbrella["frame"]) => Promise<void>;
  /** getters */
  @(namespace(StoreModule.Frame).Getter(FrameGetters.GetFinish))
  public finish!: Finish;
  @(namespace(StoreModule.Frame).Getter(FrameGetters.GetFinial))
  public finial!: option.Finial | null;
  @(namespace(StoreModule.Frame).Getter(FrameGetters.GetFinialFinish))
  public finialFinish!: Finish;
  @(namespace(StoreModule.Frame).Getter(FrameGetters.GetBottomPole))
  public bottomPole!: option.BottomPole;
  @(namespace(StoreModule.Frame).Getter(FrameGetters.GetSpigot))
  public spigot!: option.Spigot;
  @(namespace(StoreModule.Frame).Getter(FrameGetters.GetAllFrameData))
  public allFrameData!: CustomUmbrella["frame"];
}

@Component
export class ShapeStore extends Vue {
  /* SHAPE */
  /** actions */
  @(namespace(StoreModule.Shape).Action(ShapeActions.AddModel))
  public addUmbrellaModel!: (value: UmbrellaModel) => Promise<void>;
  /** getters */
  @(namespace(StoreModule.Shape).Getter(ShapeGetters.GetModel))
  public umbrellaModel!: UmbrellaModel | null;
}
