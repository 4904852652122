export enum FinishHandle {
  MS = "ms" as any,
  WG = "wg" as any,
  BK = "bk" as any,
  WH = "wh" as any,
  BZ = "bz" as any,
  SR = "sr" as any,
  HW = "hw" as any,
  MSA = "msa" as any,
  SM = "sm" as any,
  SS = "ss" as any,
  GS = "gs" as any,
  ASH = "ash" as any,
  GBK = "gbk" as any,
  CB = "cb" as any,
  CH = "ch" as any
}

export enum FinishDisplayName {
  MS = "Brushed Silver",
  WG = "Golden Oak",
  BK = "Onyx",
  WH = "Alpine White",
  BZ = "Desert Bronze",
  SR = "Platinum",
  HW = "Heather Willow",
  SM = "Silver Mist",
  SS = "Stainless Steel",
  GS = "Galvanized Steel",
  ASH = "Ash Wood",
  MSA = "Matte Silver Anodized",
  GBK = "Gloss Black",
  CB = "Carbon",
  CH = "Chrome"
}

export interface Finish {
  handle: FinishHandle;
  display_name: FinishDisplayName;
}
